import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang'

import checkIn from '@/pages/CheckIn'
import Commitment from '@/pages/Commitment'
import NatificationFile from '@/pages/NotificationFile'
import Result from '@/pages/Result'

Vue.use(Router)

const routes = [
  { path: '/', component: checkIn, meta: {title: i18n.t('checkIn.pageTitle')} },
  { path: '/commitment', component: Commitment, meta: {title: i18n.t('commitment.pageTitle')} },
  { path: '/notification-file', component: NatificationFile, meta: {title: i18n.t('notificationFile.pageTitle')} },
  { path: '/commit-success', component: Result, meta: {title: i18n.t('commitResult.pageTitle')} },
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if(to.path !== '/') {
        return {y: 0}
      }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router