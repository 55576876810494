<template>
  <div id="app">
    <div class="lang-select">
      <lang-toggle></lang-toggle>
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import LangToggle from "@/components/LangToggle"
export default {
  name: 'App',
  components: {
    LangToggle,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
}
.lang-select {
  position: absolute;
  top: 20px;
  left: 10px;
  right: 10px;
}
</style>
