export const areaList = {
  province_list: { 100000: 'Beijing', 110000: 'Shanghai', 120000: 'Tianjin', 130000: 'Chungking', 140000: 'Hebei', 150000: 'Shanxi', 160000: 'Liaoning', 170000: 'Jilin', 180000: 'Heilongjiang', 190000: 'Jiangsu', 200000: 'Zhejiang', 210000: 'Anhui', 220000: 'Fujian', 230000: 'Jiangxi', 240000: 'Shandong', 250000: 'Henan', 260000: 'Hubei', 270000: 'Hunan', 280000: 'Guangdong', 290000: 'Hainan', 300000: 'Sichuan', 310000: 'Guizhou', 320000: 'Yunnan', 330000: 'Shaanxi', 340000: 'Gansu', 350000: 'Qinghai', 360000: 'Inner Mongolia', 370000: 'Guangxi', 380000: 'Ningxia', 390000: 'Sinkiang', 400000: 'Tibet' },
  city_list: {
    100100: "Dongcheng",
    100200: "Xicheng",
    100300: "Chaoyang",
    100400: "Fengtai",
    100500: "Shijingshan",
    100600: "Haidian",
    100700: "Shunyi",
    100800: "Tongzhou",
    100900: "Daxing",
    101000: "Fangshan",
    101100: "Mentougou",
    101200: "Changping",
    101300: "Pinggu",
    101400: "Miyun",
    101500: "Huairou",
    101600: "Yanqing",
    110100: "Huangpu",
    110200: "Xuhui",
    110300: "Changning",
    110400: "Jing'an",
    110500: "Putuo",
    110600: "Hongkou",
    110700: "Yangpu",
    110800: "Pudong",
    110900: "Minhang",
    111000: "Baoshan",
    111100: "Jiading",
    111200: "Jinshan",
    111300: "Songjiang",
    111400: "Qingpu",
    111500: "Fengxian",
    111600: "Chongming",
    120100: "Heping",
    120200: "Hedong",
    120300: "Hexi",
    120400: "Nankai",
    120500: "Hebei",
    120600: "Hongqiao",
    120700: "Binhai",
    120800: "Dongli",
    120900: "Xiqing",
    121000: "Jinnan",
    121100: "Beichen",
    121200: "Wuqing",
    121300: "Baodi",
    121400: "Ninghe",
    121500: "Jinghai",
    121600: "Jizhou",
    130100: "Yuzhong",
    130200: "Dadukou",
    130300: "Jiangbei",
    130400: "Shapingba",
    130500: "Jioulongpo",
    130600: "Nanan",
    130700: "Beibei",
    130800: "Yubei",
    130900: "Banan",
    131000: "Fuling",
    131100: "Qijiang ",
    131200: "Dazu",
    131300: "Changshou",
    131400: "Jiangjin",
    131500: "Hechuan",
    131600: "Yongchuan",
    131700: "Nanchuan",
    131800: "Bishan",
    131900: "Tongliang",
    132000: "Tongnan",
    132100: "Rongchang",
    132200: "Wanzhou",
    132300: "Kaizhou",
    132400: "Qianjiang",
    132500: "Liangping",
    132600: "Wulong",
    132700: "Chengkou",
    132800: "Fengdu",
    132900: "Dianjiang",
    133000: "Zhongxian",
    133100: "Yunyang",
    133200: "Fengjie",
    133300: "Wushan",
    133400: "Wuxi",
    133500: "Shizhu",
    133600: "Xiushan",
    133700: "Youyang",
    133800: "Pengshui",
    140100: "Shijiazhuang",
    140200: "Tangshan",
    140300: "Chinwangtao",
    140400: "Handan",
    140500: "Xingtai",
    140600: "Baoding",
    140700: "Zhangjiakou",
    140800: "Chengde",
    140900: "Cangzhou",
    141000: "Langfang",
    141100: "Hengshui",
    141200: "Dingzhou",
    141300: "Xinji",
    150100: "Taiyuan",
    150200: "Datong",
    150300: "Yangquan",
    150400: "Changzhi",
    150500: "Jincheng",
    150600: "Shuozhou",
    150700: "Jinzhong",
    150800: "Yuncheng",
    150900: "Xinzhou",
    151000: "Linfen",
    151100: "Lvliang",
    160100: "Shenyang",
    160200: "Dalian",
    160300: "Anshan",
    160400: "Fushun",
    160500: "Benxi",
    160600: "Dandong",
    160700: "Jinzhou",
    160800: "Yingkou",
    160900: "Fuxin",
    161000: "Liaoyang",
    161100: "Panjin",
    161200: "Tieling",
    161300: "Chaoyang",
    161400: "Huludao",
    170100: "Changchun",
    170200: "Jilin",
    170300: "Siping",
    170400: "Liaoyuan",
    170500: "Tonghua",
    170600: "Baishan",
    170700: "Baicheng",
    170800: "Songyuan",
    170900: "Yanbian",
    171000: "Changbai Mountain",
    171100: "Meihekou",
    171200: "Gongzhuling",
    180100: "Harbin",
    180200: "Qiqihar",
    180300: "Jixi",
    180400: "Hegang",
    180500: "Shuangyashan",
    180600: "Daqing",
    180700: "Yichun",
    180800: "Kiamusze",
    180900: "Qitaihe",
    181000: "Mudanjiang",
    181100: "Heihe",
    181200: "Suihua",
    181300: "Daxing'anling",
    190100: "Nanjing",
    190200: "Wuxi",
    190300: "Xuzhou",
    190400: "Changzhou",
    190500: "Suzhou",
    190600: "Nantong",
    190700: "Lianyungang",
    190800: "Huai'an",
    190900: "Yancheng",
    191000: "Yangzhou",
    191100: "Zhenjiang",
    191200: "Taizhou",
    191300: "Suqian",
    200100: "Hangzhou",
    200200: "Ningbo",
    200300: "Wenzhou",
    200400: "Shaoxing",
    200500: "Huzhou",
    200600: "Jiaxing",
    200700: "Jinhua",
    200800: "Quzhou",
    200900: "Taizhou",
    201000: "Lishui",
    201100: "Zhoushan",
    210100: "Hefei",
    210200: "Wuhu",
    210300: "Bangbu",
    210400: "Huainan",
    210500: "Ma'anshan",
    210600: "Huaibei",
    210700: "Tongling",
    210800: "Anqing",
    210900: "Huangshan",
    211000: "Fuyang",
    211100: "Suzhou",
    211200: "Chuzhou",
    211300: "Lu'an",
    211400: "Xuancheng",
    211500: "Chizhou",
    211600: "Bozhou",
    220100: "Fuzhou",
    220200: "Xiamen",
    220300: "Zhangzhou",
    220400: "Quanzhou",
    220500: "Sanming",
    220600: "Putian",
    220700: "Nanping",
    220800: "Longyan",
    220900: "Ningde",
    221000: "Pingtan",
    230100: "Nanchang",
    230200: "Jiujiang",
    230300: "Shangrao",
    230400: "Fuzhou",
    230500: "Yichun",
    230600: "Ji'an",
    230700: "Ganzhou",
    230800: "Jingdezhen",
    230900: "Pingxiang",
    231000: "Xinyu",
    231100: "Yingtan",
    240100: "Jinan",
    240200: "Qingdao",
    240300: "Zibo",
    240400: "Zaozhuang",
    240500: "Dongying",
    240600: "Yantai",
    240700: "Weifang",
    240800: "Jining",
    240900: "Tai'an",
    241000: "Weihai",
    241100: "Rizhao",
    241200: "Bingzhou",
    241300: "Dezhou",
    241400: "Liaocheng",
    241500: "Linyi",
    241600: "Heze",
    241700: "Laiwu",
    250100: "Zhengzhou",
    250200: "Kaifeng",
    250300: "Luoyang",
    250400: "Pingdingshan",
    250500: "Anyang",
    250600: "Hebi",
    250700: "Xinxiang",
    250800: "Jiaozuo",
    250900: "Puyang",
    251000: "Xuchang",
    251100: "Luohe",
    251200: "Sanmenxia",
    251300: "Shangqiu",
    251400: "Zhoukou",
    251500: "Zhumadian",
    251600: "Nanyang",
    251700: "Xinyang",
    251800: "Jiyuan",
    260100: "Wuhan",
    260200: "Huangshi",
    260300: "Shiyan",
    260400: "Yichang",
    260500: "Xiangyang",
    260600: "E'zhou",
    260700: "Jingmen",
    260800: "Xiaogan",
    260900: "Jingzhou",
    261000: "Huanggan",
    261100: "Xian'ning",
    261200: "Suizhou",
    261300: "Enshi",
    261400: "Xiantao",
    261500: "Qianjiang",
    261600: "Tianmen",
    261700: "Shennongjia",
    270100: "Changsha",
    270200: "Zhuzhou",
    270300: "Xiangtan",
    270400: "Hengyang",
    270500: "Shaoyang",
    270600: "Yueyang",
    270700: "Changde",
    270800: "Zhangjiajie",
    270900: "Yiyang",
    271000: "Loudi",
    271100: "Chenzhou",
    271200: "Yongzhou",
    271300: "Huaihua",
    271400: "Xiangxi",
    280100: "Guangzhou",
    280200: "Shenzhen",
    280300: "Zhuhai",
    280400: "Shantou",
    280500: "Foshan",
    280600: "Shaoguan",
    280700: "Zhanjiang",
    280800: "Zhaoqing",
    280900: "Jiangmen",
    281000: "Maoming",
    281100: "Huizhou",
    281200: "Meizhou",
    281300: "Shanwei",
    281400: "Heyuan",
    281500: "Yangjiang",
    281600: "Qingyuan",
    281700: "Dongguan",
    281800: "Zhongshan",
    281900: "Chaozhou",
    282000: "Jieyang",
    282100: "Yunfu",
    290100: "Haikou",
    290200: "Sanya",
    290300: "Sansha",
    290400: "Danzhou",
    290500: "Wuzhishan",
    290600: "Wenchang",
    290700: "Qionghai",
    290800: "Wanning",
    290900: "Dongfang",
    291000: "Dingan",
    291100: "Tunchang",
    291200: "Chengmai",
    291300: "Lingao",
    291400: "Baisha",
    291500: "Changjiang",
    291600: "Ledong",
    291700: "Lingshui",
    291800: "Baoting",
    291900: "Qiongzhong",
    292000: "Yangpu",
    300100: "Chengdu",
    300200: "Mianyang ",
    300300: "Zigong",
    300400: "Panzhihua",
    300500: "Luzhou",
    300600: "Deyang",
    300700: "Guangyuan",
    300800: "Suining",
    300900: "Neijiang",
    301000: "Leshan",
    301100: "Ziyang",
    301200: "Yibin",
    301300: "Nanchong",
    301400: "Dazhou",
    301500: "Ya'an",
    301600: "Ngawa",
    301700: "Garze",
    301800: "Liangshan",
    301900: "Guang'an",
    302000: "Bazhong",
    302100: "Menshan",
    310100: "Guiyang",
    310200: "Zunyi",
    310300: "Liupanshui",
    310400: "Anshun",
    310500: "Tongren",
    310600: "Bijie",
    310700: "Qianxinan",
    310800: "Qiandongnan",
    310900: "Qiannan",
    320100: "Kunming",
    320200: "Qujing",
    320300: "Yuxi",
    320400: "Zhaotong",
    320500: "Baoshan",
    320600: "Lijiang",
    320700: "Puer",
    320800: "Lincang",
    320900: "Dehong",
    321000: "Nujiang",
    321100: "Diqing",
    321200: "Dali",
    321300: "Chuxiong",
    321400: "Honghe",
    321500: "Wenshan",
    321600: "Sipsongpanna",
    330100: "Xi'an",
    330200: "Baoji",
    330300: "Xianyang",
    330400: "Weinan",
    330500: "Tongchuan",
    330600: "Yan'an",
    330700: "Yulin",
    330800: "Ankang",
    330900: "Hanzhong",
    331000: "Shangluo",
    340100: "Lanchow",
    340200: "Jiayuguan",
    340300: "Jinchang",
    340400: "Baiyin",
    340500: "Tianshui",
    340600: "Jiuquan",
    340700: "Zhangye",
    340800: "Wuwei",
    340900: "Dingxi",
    341000: "Longnan",
    341100: "Pingliang",
    341200: "Qingyang",
    341300: "Linxia",
    341400: "Gannan",
    350100: "Xining",
    350200: "Haidong",
    350300: "Haibei",
    350400: "Huangnan",
    350500: "Hainan",
    350600: "Golog",
    350700: "Yushu",
    350800: "Haixi",
    360100: "Hohehot",
    360200: "Baotou",
    360300: "Wuhai",
    360400: "Chifeng",
    360500: "Tongliao",
    360600: "Ordos",
    360700: "Hulun Buir",
    360800: "Bayan Nur",
    360900: "Ulanqab",
    361000: "Hinggan League",
    361100: "Xilingo League",
    361200: "Alxa League",
    370100: "Nanning",
    370200: "Liuzhou",
    370300: "Guilin",
    370400: "Wuzhou",
    370500: "Beihai",
    370600: "Fangchenggang",
    370700: "Qinzhou",
    370800: "Guigang",
    370900: "Yulin",
    371000: "Baise",
    371100: "Hezhou",
    371200: "Hechi",
    371300: "Laibin",
    371400: "Chongzuo",
    380100: "Yinchuan",
    380200: "Shizuishan",
    380300: "Wuzhong",
    380400: "Guyuan",
    380500: "Zhongwei",
    390100: "Urumqi",
    390200: "Karamay",
    390300: "Turpan",
    390400: "Hami",
    390500: "Aksu",
    390600: "Kashgar",
    390700: "Hotan",
    390800: "Changji",
    390900: "Bortala",
    391000: "Bayingol",
    391100: "Kizilsu Kirghiz",
    391200: "Ili Kazak",
    400100: "Lhasa",
    400200: "Qamdo",
    400300: "Shigatse",
    400400: "Nyingchi",
    400500: "Lhoka",
    400600: "Nagqu",
    400700: "Ngari",
  },
  county_list: {},
};
