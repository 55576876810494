export default {
  appHeader: {
    title: 'Vue App',
    checkIn_title: 'Check-in at DongYuan Quarantine Hotel',
  },
  common: {
    registeredSuccessfully: 'Registered successfully',
    saveSuccessfully: 'Save successfully',
    saveFail: 'Save failed, please try again',
  },
  checkIn: {
    pageTitle: 'Check-in at DongYuan Quarantine Hotel',
    hotelName: 'DongYuan Quarantine Hotel',
    hotelAddress: 'Dongyuan Building, Dongyuan 1st Street, West District, Huangpu District, Guangzhou City, Guangdong Province',
    tips: 'In order to do a good job in the prevention and treatment of new coronary pneumonia, please cooperate with us in the epidemiological history investigation',
    formTitleBaseInfo: 'Basic Information',
    formUserName: 'Full Name',
    formGender: 'Gender',
    formCountry: 'Nationality',
    formIdCardType: 'Type of Certificate',
    formIDCardType_1: 'Resident Identity Card',
    formIDCardType_2: `Residents' Mainland Travel Permit`,
    formIDCardType_3: 'Passport',
    formIDCardType_4: `Residents' Mainland Travel Permit or other valid travel documents`,
    formIdCardTypeTips: 'Chinese citizens are resident ID cards; Hong Kong and Macau residents are Hong Kong and Macau residents’ travel permits to and from the Mainland; Taiwan residents, Taiwan residents, and Taiwan residents travel to and from the mainland or other valid travel documents; foreign citizens, passports',
    formIdCardNum: 'ID number',
    formYearOld: 'Age',
    formPhoneNum: 'Mobile phone number',
    formEmail: 'Email Address',
    formArea: 'District of domestic address',
    formAddress: 'Domestic address',
    formEmergencyContactName: 'Emergency contact name',
    formEmergencyContactPhoneNum: 'Emergency Contact Phone Number',
    formContactNameAndWay: 'Second Contact Name(Chinese) and Phone Number',
    formDiseases: 'With or without related diseases',
    formDiseasesHypertension: 'Hypertension',
    formDiseasesDiabetes: 'Diabetes',
    formDiseasesCoronaryHeart: 'Coronary Heart Disease',
    formDiseasesChronicHepatitis: 'Chronic hepatitis',
    formDiseasesHeart: 'Heart disease',
    formDiseasesNothing: 'None',
    formFoodAllergy: 'Food allergies',
    formFoodAllergiesDetails: 'Please specify in detail what kind of food allergies',
    formReligious: 'Religious belief',
    formRequirements: 'Special Requirements (No promise 100% Satisfy)',
    formRegistrationDate: 'Registration Date',
    formTitle14DSituation: 'Situation in the past 14 days',
    formFromAbroad: 'From Abroad ？',
    formEntryDate: 'Date of entry',
    formCountryAndCity: 'Country (region) or city of travel or residence in the past 14 days',
    formCountryAndCityAndDate: 'The country (region), city and corresponding date that you passed through before entering China',
    formGetTouchDate: 'Exposure to suspected cases (yes, no), Date',
    formTravelMode: 'Travel mode',
    formTravelMode_1: 'Train number and departure date&time',
    formTravelMode_2: 'Airplane flight and seat number',
    formTravelMode_3: 'Bus Number and departure date&time',
    formTravelMode_4: 'Own Car Number',
    formTravelMode_5: 'Other',
    formPeersNameAndWay: `Companion's name and contact details`,
    formTitleReleaseDiction: 'Where to go after being released from Quarantine',
    formReleaseRegion: 'Area',
    formReleaseAddress: 'Address',
    formReleaseVehicle: 'Transportation',
    formTitleHealthStatus: 'Current Health Status',
    formTemperature: 'Body temperature',
    formHealthStatus: 'Current Health Status',
    formHealthStatusFever: 'Fever',
    formHealthStatusDryCough: 'Dry cough',
    formHealthStatusWeakness: 'Fatigue',
    formHealthStatusSoreThroat: 'Sore throat',
    formHealthStatusChestTightness: 'Chest tightness',
    formHealthStatusOtherSymptoms: 'other',
    formHealthStatusNoneOfTheAbove: 'Without the above abnormal symptoms',
    formSignDate: 'Date',
    formRoommate: 'Is there anyone living with you',
    checkboxText_1: 'I have read and agree to the "Commitment Letter for Live-in Application"',
    checkboxText_2: 'I have read and agree to the "Notice of Check-in at the Centralized Quarantine Observation Point"',
    checkboxText_3: 'I promise that the information provided above is true and accurate. If not, I am willing to bear all the consequences and legal responsibilities caused by this.',
    signTips: 'Fill in the signature of the person (click on the picture to re-sign)',
    signGuide: 'Click signature',
    signNameTips: 'Please sign your name',
    men: 'male',
    women: 'female',
    have: 'Yes',
    without: 'No',
    certPlace: 'click here to pick Certificate',
    datePlace: 'click here to pick Date',
    areaPlace: 'click here to pick Area',
    inputGuide: 'please input',
    submit: 'Submit',
    tipsSignCommitment: 'Please click and fill in 《Commitment》',
    tipsSignNotificationFile: 'Please click and fill in 《Notice of Check-in for Centralized Isolation Observation Point》',
  },
  commitment: {
    pageTitle: 'Commitment',
    name: 'My name',
    sex: 'Gender',
    idNumber: 'ID number/passport number',
    reason: 'For reasons of',
    partner: 'I voluntarily live with the person who intends to live with',
    room: 'Isolate the hotel in Guangzhou Live in the same room during the centralized isolation medical observation period.',
    alert: 'The staff has informed me and the person who intends to live with the prevention and control work requirements and the risk of cross-infection, and there may be cases where the person living with the same person needs to be isolated for another 14 days when the nucleic acid test is positive. However, I and the person who intends to live with me still insist on living in the same room, and voluntarily and strictly abide by the various epidemic prevention regulations, and I and the person who intends to live with me voluntarily bear all risks and legal responsibilities.',
    sure: 'I guarantee that the information filled in is completely true. If there is any false information, I voluntarily assume all legal responsibilities.',
    hotel: 'Hotel',
    roomId: 'Room No',
    sign: 'Promiser’s signature',
    private: 'I have fully understood the above prevention and control requirements, infection risks and legal responsibilities, and still insist on living in the same room with the promisee, and voluntarily and strictly abide by the epidemic prevention regulations, and voluntarily assume all risks and legal responsibilities by myself and the promisee.',
    partnerSign: 'Signature of the person who intends to live with',
    desc: 'Remarks: In case of epidemic prevention and control needs, the difference in housing price shall be made up when the housing is notified.',
    inputGuide: 'please input',
    shortInputGuide: 'input',
    year: 'year',
    month: 'month',
    day: 'day',
    tipsSignatureSave_1: `Please save the pledger's signature`,
    tipsSignatureSave_2: `Please save the signature of the person who lives with you`,
  },
  notificationFile: {
    pageTitle: 'Notice of Check-in for Centralized Isolation Observation Point',
    content_1: '1. Strictly abide by all relevant systems of the centralized isolation point. During the centralized isolation period, it is strictly forbidden to go out without authorization, visit each other, meet with visitors, and other behaviors that affect the prevention and control of the epidemic.',
    content_2: '2. Actively cooperate with medical staff to carry out isolation observation, take body temperature twice a day (once at 7:00 and 14:00), and truthfully report on my health that day.',
    content_3: '3. Do not disseminate information related to the epidemic without authorization, do not conceal, do not lie, or report your own situation without delay.',
    content_4: '4. During the observation period, if symptoms such as fever, cough, loss of taste and smell occur, immediately call the reception staff and cooperate with the staff to carry out relevant inspections.',
    content_5: '5. Meals for three meals a day will be placed at the door of the room by the staff at regular intervals, and the quarantine staff will pick them up by themselves. The household garbage is collected by the quarantine personnel, and the garbage bags are tied tightly and placed at the door of the corridor of the room. The staff come to collect it every morning. New garbage bags will also be placed at the door of the room regularly. Quarantine personnel must wear a mask when picking up meals, placing garbage, or when opening the door of the room before opening the door. At other times, it is prohibited to go out of the room door unless there are special circumstances.',
    content_6: '6. During the quarantine period, the three meals will be delivered by the catering company, which may not be able to meet the individual needs of each quarantined person. Please understand. (If there are special circumstances such as food allergies, please inform the staff in time).',
    content_7: '7. In order to ensure food safety and the needs of epidemic prevention and control, take-out catering and food are not allowed during the isolation period (if drugs are required, contact the staff in advance); for the health and safety of the isolation personnel, alcohol and food are not allowed to enter the room.',
    content_8: '8. It is forbidden to smoke, drink alcohol, and stop visiting. If you do not cooperate with the isolation work, it will affect your isolation time. If serious consequences are caused, they will be investigated for criminal responsibility in accordance with the law.',
    content_9: '9. If the quarantined person carries a pet, the pet should be separated from the carrier. During the quarantine period, it is prohibited to bring the pet out of the room. The garbage and excrement caused by the pet should also be cleaned and disinfected by the quarantine staff.',
    content_10: '10. The quarantine personnel prohibit other quarantined objects from making unrealistic comments.',
    content_11: '11. This isolation point provides psychological hotline counseling channels for quarantined persons. If necessary, you can call the following counseling hotlines:Hotline of Guangzhou Psychological Crisis Intervention Center: 020-81899120 (24 hours)',
    content_12: '12. The centralized isolation observation point is the "Dongyuan Building Hotel Isolation Point", address: Dongyuan Building, Dongyuan 1st Street, West District, Huangpu District, Guangzhou City, Guangdong Province',
    content_13: 'Hotline of Guangzhou Psychological Crisis Intervention Center: 020-81899120 (24 hours)',
    content_14: 'Psychological assistance of Huangpu District Mental Health Prevention Center: 15915923478 (24 hours)',
    idCardUpload: '身份证/护照上传（有本人照片的一页）',
    date: 'Date',
  },
  commitResult: {
    pageTitle: 'Result',
    message: 'Commit successfully',
  },
  signature: {
    close: 'close',
    clear: 'clear',
    save: 'save',
  },
  langSelect: {
    pickerTitle: '当前语言',
    text: '点击切换中英文版本',
  }
}