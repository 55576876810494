// initial state
const state = () => ({
  status: false,
  lang: 'zh-CN',
})

// getters
const getters = {}

// actions
const actions = {
  toggleLangPicker ({ commit }, status) {
    commit('setLang', status)
  },
  setLanguage ({ commit }, lang) {
    commit('setLanguage', lang)
  }
}

// mutations
const mutations = {
  setLang (state, status) {
    state.status = status
  },
  setLanguage (state, lang) {
    state.lang = lang
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}