<template>
  <!-- 提交成功 -->
  <div class="main result">
    <div class="result-icon_wrap">
      <img src="../../assets/commit-success@2x.png">
      <div>{{$t("commitResult.message")}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Result",
  props: {},
  data() {
    return {

    }
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.result {
  position: relative;
  text-align: center;
  z-index: -1;
  .result-icon_wrap {
    width: 40%;
    display: inline-block;
    margin-top: 30%;
    img {
      width: 100%;
    }
  }
}
</style>