// initial state
const state = () => ({
  commitment: {
    applicantName: "", // 申请人姓名 有同住人时需要填
    applicantSex: "", // 申请人性别 有同住人时需要填
    applicantIdNumber: "", // 申请人身份证号 有同住人时需要填
    applicantReason: "", // 申请人原因 有同住人时需要填
    applicantAutographUrl: "", // 申请人签名图片链接 有同住人时需要填
    applicantAutographYear: "", // 申请人签名 年 有同住人时需要填
    applicantAutographMonth: "", // 申请人签名 月 有同住人时需要填
    applicantAutographDay: "", // 申请人签名 日 有同住人时需要填
    roommateName: "", // 同住人姓名 有同住人时需要填
    roommateSex: "", // 同住人性别 有同住人时需要填
    roommateIdNumber: "", // 同住人身份证号码 有同住人时需要填
    roommateAutographUrl: "", // 同住人签名图片链接 有同住人时需要填
    roommateAutographYear: "", // 同住人签名 年 有同住人时需要填
    roommateAutographMonth: "", // 同住人签名 月 有同住人时需要填
    roommateAutographDay: "", // 同住人签名 日 有同住人时需要填
    hotelName: "", // 酒店名称 有同住人时需要填
    hotelRoomNumber: "", // 酒店房号 有同住人时需要填
  },
  commitmentAllCheck: false,
  notificationFile: {
    noticeAutographUrl: "", // 告知书签名图片链接
    // idCardUrl: "", // 告知书签名图片链接
    noticeDate: "", // 通知书填写日期 yyyy-mm-dd
  },
  notificationFileAllCheck: false,
})

// getters
const getters = {}

// actions
const actions = {
  saveCommitmentParams ({ commit }, params) {
    commit('setCommitment', params)
  },
  saveCommitmentAllCheck ({ commit }, status) {
    commit('setCommitmentAllCheck', status)
  },
  saveNotificationFileParams ({ commit }, params) {
    commit('saveNotificationFile', params)
  },
  saveNotificationFileAllCheck ({ commit }, status) {
    commit('setNotificationFileAllCheck', status)
  },
}

// mutations
const mutations = {
  setCommitment (state, params) {
    state.commitment = params
  },
  setCommitmentAllCheck (state, status) {
    state.commitment = status
  },
  saveNotificationFile (state, params) {
    state.notificationFile = params
  },
  setNotificationFileAllCheck (state, status) {
    state.commitment = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}