<template>
  <div class="main">
    <!-- 入住登记 -->
    <div class="check-in_form">
      <!-- {{$t('langSelect.pickerTitle')}} -->
      <div class="check-in_header-card">
        <p class="check-in-title">{{ $t("checkIn.pageTitle") }}</p>
        <div class="check-in_card">
          <img
            class="check-in_card-left"
            src="../../assets/pic@2x.png"
            :alt="$t('checkIn.hotelName')"
          />
          <div class="check-in_card-right">
            <p class="hotel-name">{{ $t("checkIn.hotelName") }}</p>
            <p style="margin-bottom: 12px">
              <a href="tel:020-668008" class="hotel-phone">
                <img
                  style="width: 14px"
                  src="../../assets/phone.png"
                  :alt="$t('checkIn.phone')"
                />
                <span>020-668008</span>
              </a>
            </p>
            <p class="address-icon">
              <a class="hotel-address">
                <img
                  style="width: 14px"
                  src="../../assets/address.png"
                />
                <span>{{ $t("checkIn.hotelAddress") }}</span>
              </a>
            </p>
          </div>
        </div>
        <van-notice-bar
          class="tips"
          color="#1989fa"
          background="#ecf9ff"
          left-icon="info-o"
        >
          {{ $t("checkIn.tips") }}
        </van-notice-bar>
      </div>
      <div class="check-in_form-card">
        <van-form @submit="onSubmit">
          <h2 class="check-in_form-card__title">
            {{ $t("checkIn.formTitleBaseInfo") }}
          </h2>
          <div class="check-in_form-card__detail">
            <van-field
              v-model="form.name"
              class="form-required-item"
              :name="$t('checkIn.formUserName')"
              :label="$t('checkIn.formUserName')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              class="reset-class custom-checkbox form-required-item"
              :name="$t('checkIn.formGender')"
              :label="$t('checkIn.formGender')"
              :rules="[{ required: true}]"
            >
              <template #input>
                <van-radio-group v-model="form.sex" direction="horizontal">
                  <van-radio name="男">{{ $t("checkIn.men") }}</van-radio>
                  <van-radio name="女">{{ $t("checkIn.women") }}</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-model="form.nationality"
              class="form-required-item"
              :name="$t('checkIn.formCountry')"
              :label="$t('checkIn.formCountry')"
              :placeholder="$t('checkIn.inputGuide')"
              :rules="[{ required: true}]"
            />
            <van-field
              readonly
              clickable
              name="picker"
              class="form-required-item"
              :value="form.documentTypeText"
              :label="$t('checkIn.formIdCardType')"
              :placeholder="$t('checkIn.certPlace')"
              @click="showPicker = true"
            />
            <p class="required idCard-tips">
              {{ $t("checkIn.formIdCardTypeTips") }}
            </p>
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <van-field
              v-model="form.certificateNo"
              class="form-required-item"
              :name="$t('checkIn.formIdCardNum')"
              :label="$t('checkIn.formIdCardNum')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.age"
              class="form-required-item"
              :name="$t('checkIn.formYearOld')"
              :label="$t('checkIn.formYearOld')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.phone"
              class="form-required-item"
              :name="$t('checkIn.formPhoneNum')"
              :label="$t('checkIn.formPhoneNum')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.mailbox"
              class="form-required-item"
              :name="$t('checkIn.formEmail')"
              :label="$t('checkIn.formEmail')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.domesticAddressArea"
              class="form-required-item"
              :name="$t('checkIn.formArea')"
              :label="$t('checkIn.formArea')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.domesticAddress"
              class="form-required-item"
              :name="$t('checkIn.formAddress')"
              :label="$t('checkIn.formAddress')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.emergencyContactName"
              class="form-required-item"
              :name="$t('checkIn.formEmergencyContactName')"
              :label="$t('checkIn.formEmergencyContactName')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.emergencyContactPhone"
              class="form-required-item"
              :name="$t('checkIn.formEmergencyContactPhoneNum')"
              :label="$t('checkIn.formEmergencyContactPhoneNum')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.contactsNamePhone"
              class="form-required-item"
              :name="$t('checkIn.formContactNameAndWay')"
              :label="$t('checkIn.formContactNameAndWay')"
              :placeholder="$t('checkIn.inputGuide')"
              :rules="[
                { required: true},
              ]"
            />
            <van-field
              class="custom-checkbox reset-class form-required-item"
              name="checkboxGroup"
              :label="$t('checkIn.formDiseases')"
            >
              <template #input>
                <van-checkbox-group
                  v-model="diseasesValue"
                  direction="horizontal"
                >
                  <van-checkbox v-for="item in diseasesList" :key="item.name" :name="item.name" shape="square">{{
                    item.label
                  }}</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              class="reset-class custom-checkbox form-required-item"
              v-model="form.foodAllergy"
              :name="$t('checkIn.formFoodAllergy')"
              :label="$t('checkIn.formFoodAllergy')"
              :rules="[{ required: true}]"
            >
              <template #input>
                <van-radio-group
                  v-model="form.foodAllergy"
                  direction="horizontal"
                >
                  <van-radio name="1">{{ $t("checkIn.have") }}</van-radio>
                  <van-radio name="0">{{ $t("checkIn.without") }}</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              class="form-required-item"
              v-show="form.foodAllergy == 1"
              v-model="form.foodName"
              :placeholder="$t('checkIn.formFoodAllergiesDetails')"
              :rules="[
                {
                  required: form.foodAllergy == 1
                },
              ]"
            />
            <van-field
              v-model="form.religiousBelief"
              class="form-required-item"
              :name="$t('checkIn.formReligious')"
              :label="$t('checkIn.formReligious')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              v-model="form.specialRequirements"
              rows="2"
              autosize
              :label="$t('checkIn.formRequirements')"
              type="textarea"
              :placeholder="$t('checkIn.inputGuide')"
              show-word-limit
            />
            <van-field
              readonly
              clickable
              name="calendar"
              class="form-required-item"
              :value="form.registrationDate"
              :label="$t('checkIn.formRegistrationDate')"
              :placeholder="$t('checkIn.datePlace')"
              @click="onShowCalendar('1')"
            />
          </div>
          <h2 class="check-in_form-card__title">
            {{ $t("checkIn.formTitle14DSituation") }}
          </h2>
          <div class="check-in_form-card__detail">
            <van-field
              class="reset-class custom-checkbox form-required-item"
              v-model="form.fromAbroad"
              :name="$t('checkIn.formFromAbroad')"
              :label="$t('checkIn.formFromAbroad')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            >
              <template #input>
                <van-radio-group
                  v-model="form.fromAbroad"
                  direction="horizontal"
                >
                  <van-radio name="1">{{ $t("checkIn.have") }}</van-radio>
                  <van-radio name="0">{{ $t("checkIn.without") }}</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-if="form.fromAbroad == 1"
              readonly
              clickable
              name="calendar"
              class="form-required-item"
              :value="form.entryTime"
              :label="$t('checkIn.formEntryDate')"
              :placeholder="$t('checkIn.datePlace')"
              :rules="[{ required: form.fromAbroad == 1}]"
              @click="onShowCalendar('3')"
            />
            <!-- 近14天内履行或者居住国家（地区）、城市 -->
            <van-field
              v-model="form.residentialAreaOf14day"
              class="form-required-item"
              :name="$t('checkIn.formCountryAndCity')"
              :label="$t('checkIn.formCountryAndCity')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <!-- 入境前途径国家（地区）、城市及相应日期 -->
            <van-field
              v-model="form.routeCountryCityDate"
              class="form-required-item"
              :name="$t('checkIn.formCountryAndCityAndDate')"
              :label="$t('checkIn.formCountryAndCityAndDate')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <!-- 是否接触可以病例（是、否），日期 -->
            <van-field
              v-model="form.contactCases"
              class="form-required-item"
              :name="$t('checkIn.formGetTouchDate')"
              :label="$t('checkIn.formGetTouchDate')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <!-- 出行方式 TODO -->
            <div class="check-in_ipt-field">
              <p class="custom-label">{{ $t("checkIn.formTravelMode") }}</p>
              <div v-for="item in travelModeList" :key="item.checkKey" class="custom-checkbox check-in_ways-item">
                <van-checkbox v-model="formTravelMode[item.checkKey]" shape="square">{{
                  item.label
                }}</van-checkbox>
                <div class="check-in_ipt-field-right">
                  <van-field
                    v-model="form[item.inputKey]"
                    class="form-required-item"
                    :rules="[{ required: formTravelMode[item.checkKey]}]"
                    :placeholder="$t('checkIn.inputGuide')"
                  />
                </div>
              </div>
            </div>
            <!-- 同行人姓名与手机号码 -->
            <van-field
              class="form-required-item"
              v-model="form.peerNamePhone"
              :name="$t('checkIn.formPeersNameAndWay')"
              :label="$t('checkIn.formPeersNameAndWay')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
          </div>
          <h2 class="check-in_form-card__title">
            {{ $t("checkIn.formTitleReleaseDiction") }}
          </h2>
          <div class="check-in_form-card__detail">
            <!-- 解除隔离-地区 -->
            <van-field
              class="form-required-item"
              readonly
              clickable
              :columns-num="2"
              name="area"
              :value="form.area"
              :label="$t('checkIn.formReleaseRegion')"
              :placeholder="$t('checkIn.areaPlace')"
              @click="showArea = true"
            />
            <van-popup v-model="showArea" position="bottom">
              <van-area
                :area-list="areaList"
                @confirm="onAreaConfirm"
                @cancel="showArea = false"
              />
            </van-popup>
            <!--  解除隔离-详细地址 -->
            <van-field
              class="form-required-item"
              v-model="form.areaDetail"
              :name="$t('checkIn.formReleaseAddress')"
              :label="$t('checkIn.formReleaseAddress')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <!-- TODO 交通工具 -->
            <div class="check-in_ipt-field">
              <p class="custom-label">{{ $t("checkIn.formReleaseVehicle") }}</p>
              <div v-for="item in fromReleaseModeList" :key="item.checkKey" class="custom-checkbox check-in_ways-item">
                <van-checkbox v-model="formFromReleaseMode[item.checkKey]" shape="square">{{
                  item.label
                }}</van-checkbox>
                <div class="check-in_ipt-field-right">
                  <van-field
                    class="form-required-item"
                    v-model="form[item.inputKey]"
                    :rules="[{ required: formFromReleaseMode[item.checkKey]}]"
                    :placeholder="$t('checkIn.inputGuide')"
                  />
                </div>
              </div>
            </div>
          </div>
          <h2 class="check-in_form-card__title">
            {{ $t("checkIn.formTitleHealthStatus") }}
          </h2>
          <div class="check-in_form-card__detail">
            <!-- 体温 -->
            <van-field
              class="form-required-item"
              v-model="form.temperature"
              :name="$t('checkIn.formTemperature')"
              :label="$t('checkIn.formTemperature')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            />
            <van-field
              class="custom-checkbox reset-class form-required-item"
              name="checkboxGroup"
              :label="$t('checkIn.formHealthStatus')"
            >
              <template #input>
                <van-checkbox-group
                  v-model="healthStatusValue"
                  direction="horizontal"
                >
                  <van-checkbox v-for="item in healthStatusList" :key="item.name" :name="item.name" shape="square">{{
                    item.label
                  }}</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <!-- 签名 -->
            <div>
              <signature @save="onSaveSign"></signature>
            </div>
            <van-field
              readonly
              clickable
              class="form-required-item"
              name="calendar"
              :value="form.fillingDate"
              :label="$t('checkIn.formSignDate')"
              :placeholder="$t('checkIn.datePlace')"
              :rules="[{ required: true}]"
              @click="onShowCalendar('2')"
            />
            <van-field
              class="reset-class custom-checkbox form-required-item"
              v-model="form.hasRoommate"
              :name="$t('checkIn.formRoommate')"
              :label="$t('checkIn.formRoommate')"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.inputGuide')"
            >
              <template #input>
                <van-radio-group
                  v-model="form.hasRoommate"
                  direction="horizontal"
                >
                  <van-radio name="1">{{ $t("checkIn.have") }}</van-radio>
                  <van-radio name="0">{{ $t("checkIn.without") }}</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </div>
          <div class="check-in_document-checkbox">
            <!-- 同住申请承诺书复选 -->
            <div class="checkbox-item" v-if="form.hasRoommate == 1">
              <van-checkbox @click="checkboxClick(1)" v-model="checked_1" shape="square"></van-checkbox>
              <div @click="check(1)" class="notice-text">{{$t("checkIn.checkboxText_1")}}</div>
            </div>
            <!-- 入住告知书 -->
            <div class="checkbox-item">
              <van-checkbox @click="checkboxClick(2)" v-model="checked_2" shape="square"></van-checkbox>
              <div @click="check(2)" class="notice-text">{{$t("checkIn.checkboxText_2")}}</div>
            </div>
            <div class="checkbox-item">
              <van-checkbox @click="checkboxClick(3)" v-model="checked_3" shape="square"></van-checkbox>
              <div @click="check(3)" class="notice-text">{{$t("checkIn.checkboxText_3")}}</div>
            </div>
          </div>
          <div class="submit-button">
            <van-button :disabled="submitDisabled" :loading="submitLoading" round block type="info" native-type="submit">{{
              $t("checkIn.submit")
            }}</van-button>
          </div>
        </van-form>
      </div>
    </div>
    <van-calendar
      v-model="showCalendar"
      :min-date="minDate"
      @confirm="onDateConfirm"
    />
  </div>
</template>

<script>
// import LangSelect from "./LangSelect"
import { mapState } from "vuex"
import { Toast } from 'vant'
import Signature from "@/components/Signature"
// import { areaList } from "@vant/area-data"
import { areaList as areaList_cn } from "@/json/area_zh-CN"
import { areaList as areaList_en } from "@/json/area_en-US"
import { commitForm } from '@/api/checkIn'
// console.log(areaList)

export default {
  name: "CheckIn",
  components: {
    // LangSelect,
    Signature,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        name: "", // 姓名
        sex: "", //  性别
        nationality: "", // 国籍
        documentType: "", // 证件类型
        documentTypeText: "", // 证件类型显示文本
        certificateNo: "", // 证件号码
        age: "", // 年龄
        phone: "", // 手机号码
        mailbox: "", // 邮箱
        domesticAddressArea: "", // 国内住址所在区
        domesticAddress: "", // 国内住址
        emergencyContactName: "", // 紧急联系人姓名
        emergencyContactPhone: "", // 紧急联系人手机号码
        contactsNamePhone: "", // 联系人（中方）和联系方式
        hypertension: 0, // 高血压 1 是/ 0 否
        diabetes: 0, // 糖尿病 1/0
        coronaryHeartDisease: 0, // 冠心病 1/0
        chronicHepatitis: 0, // 慢性肝炎 1/0
        heartDisease: 0, // 心脏病 1/0
        nothing: 0, // 无 1/0
        foodAllergy: null, // 食物有无过敏 1/0
        foodName: '', // 食物名称 当食物有过敏时需要填写
        religiousBelief: "", // 宗教信仰
        specialRequirements: "", // 特殊要求
        registrationDate: "", // 登记日期
        fromAbroad: null, // 来自境外 1/0
        residentialAreaOf14day: "", // 近14天内履行或者居住国家（地区）、城市
        routeCountryCityDate: "", // 入境前途径国家（地区）、城市及相应日期
        contactCases: "", // 是否接触可以病例（是、否），日期
        byTrain: 0, // 是否乘坐火车 1/0
        trainInformation: "", // 乘坐火车信息
        byAir: 0, // 是否乘坐飞机 1/0
        flightInformation: "", // 乘坐航班信息
        byCar: 0, // 是否乘坐汽车 1/0
        carInformation: "", // 乘坐汽车信息
        selfDriving: 0, // 是否自驾 1/0
        licensePlateNumber: "", // 车牌号码
        otherTravelModes: 0, // 是否有其他出行方式 1/0
        otherInformation: "", // 其他出行信息
        peerNamePhone: "", // 同行人姓名与手机号码
        area: "", // 解除隔离-地区
        areaDetail: "", // 解除隔离-详细地址
        goTo: "", // 解除隔离-去向 area + areaDetail
        relieveByTrain: 0, // 解除后去向_是否乘坐火车 1/0
        relieveTrainInformation: "", // 解除后去向_乘坐火车信息
        relieveByAir: 0, // 解除后去向_是否乘坐飞机 1/0
        relieveFlightInformation: "", // 解除后去向_乘坐航班信息
        relieveByCar: 0, // 解除后去向_是否乘坐汽车 1/0
        relieveCarInformation: "", // 解除后去向_乘坐汽车信息
        relieveSelfDriving: 0, // 解除后去向_是否自驾 1/0
        relieveLicensePlateNumber: "", // 解除后去向_车牌号码
        relieveOtherTravelModes: 0, // 解除后去向_是否有其他出行方式 1/0
        relieveOtherInformation: "", // 解除后去向_其他出行信息
        temperature: "", // 体温
        fever: 0, // 发热 1/0
        dryCough: 0, // 干咳 1/0
        weakness: 0, // 乏力 1/0
        soreThroat: 0, // 咽痛 1/0
        chestTightness: 0, // 胸闷 1/0
        otherSymptoms: 0, // 其他症状 1/0
        noneOfTheAbove: 0, // 无上述异常症状 1/0
        autographUrl: "", // 签名图片链接
        fillingDate: "", // 填报日期
        hasRoommate: null, // 是否有同住人 1/0
        entryTime: "", // 入境日期 如果来自境外为1则需要填 yyyy-mm-dd
      },
      diseasesValue: [], // 有无相关疾病的值
      healthStatusValue: [], // 目前健康状况
      formTravelMode: {
        byTrain: null,
        byAir: null,
        byCar: null,
        selfDriving: null,
        otherTravelModes: null,
      },
      formFromReleaseMode: {
        relieveByTrain: null,
        relieveByAir: null,
        relieveByCar: null,
        relieveSelfDriving: null,
        relieveOtherTravelModes: null,
      },
      showPicker: false,
      showCalendar: false,
      showArea: false,
      // areaList,
      dateType: "",
      checked_1: false,
      checked_2: false,
      checked_3: false,
      username: "",
      minDate: new Date(2010, 0, 1), // 日历最小范围
      submitLoading: false, // 提交按钮家在状态
      // submitDisabled: true, // 提交按钮禁用状态
    };
  },
  computed: {
    ...mapState({
      'lang': state => state.lang.lang
    }),
    areaList() {
      return this.lang === 'zh-CN' ? areaList_cn : areaList_en
    },
    // 放在这才能更新
    columns() {
      return [
        this.$i18n.t("checkIn.formIDCardType_1"),
        this.$i18n.t("checkIn.formIDCardType_2"),
        this.$i18n.t("checkIn.formIDCardType_3"),
        this.$i18n.t("checkIn.formIDCardType_4"),
      ]
    },
    diseasesList() {
      return [
        {name: '1', key: 'hypertension', label: this.$i18n.t("checkIn.formDiseasesHypertension")},
        {name: '2', key: 'diabetes', label: this.$i18n.t("checkIn.formDiseasesDiabetes")},
        {name: '3', key: 'coronaryHeartDisease', label: this.$i18n.t("checkIn.formDiseasesCoronaryHeart")},
        {name: '4', key: 'chronicHepatitis', label: this.$i18n.t("checkIn.formDiseasesChronicHepatitis")},
        {name: '5', key: 'heartDisease', label: this.$i18n.t("checkIn.formDiseasesHeart")},
        {name: '6', key: 'nothing', label: this.$i18n.t("checkIn.formDiseasesNothing")},
        ]
    }, // 疾病复选
    healthStatusList(){
      return [
        {name: '1', key: 'fever', label: this.$i18n.t("checkIn.formHealthStatusFever")},
        {name: '2', key: 'dryCough', label: this.$i18n.t("checkIn.formHealthStatusDryCough")},
        {name: '3', key: 'weakness', label: this.$i18n.t("checkIn.formHealthStatusWeakness")},
        {name: '4', key: 'soreThroat', label: this.$i18n.t("checkIn.formHealthStatusSoreThroat")},
        {name: '5', key: 'chestTightness', label: this.$i18n.t("checkIn.formHealthStatusChestTightness")},
        {name: '6', key: 'otherSymptoms', label: this.$i18n.t("checkIn.formHealthStatusOtherSymptoms")},
        {name: '7', key: 'noneOfTheAbove', label: this.$i18n.t("checkIn.formHealthStatusNoneOfTheAbove")},
      ]
    }, // 健康状况复选
    travelModeList(){
      return [
        {checkKey: 'byTrain', inputKey: 'trainInformation', label: this.$i18n.t("checkIn.formTravelMode_1"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'byAir', inputKey: 'flightInformation', label: this.$i18n.t("checkIn.formTravelMode_2"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'byCar', inputKey: 'carInformation', label: this.$i18n.t("checkIn.formTravelMode_3"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'selfDriving', inputKey: 'licensePlateNumber', label: this.$i18n.t("checkIn.formTravelMode_4"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'otherTravelModes', inputKey: 'otherInformation', label: this.$i18n.t("checkIn.formTravelMode_5"), placeholder: this.$i18n.t('checkIn.inputGuide')},
      ]
    }, // 出行方式复选
    fromReleaseModeList() {
      return [
        {checkKey: 'relieveByTrain', inputKey: 'relieveTrainInformation', label: this.$i18n.t("checkIn.formTravelMode_1"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'relieveByAir', inputKey: 'relieveFlightInformation', label: this.$i18n.t("checkIn.formTravelMode_2"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'relieveByCar', inputKey: 'relieveCarInformation', label: this.$i18n.t("checkIn.formTravelMode_3"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'relieveSelfDriving', inputKey: 'relieveLicensePlateNumber', label: this.$i18n.t("checkIn.formTravelMode_4"), placeholder: this.$i18n.t('checkIn.inputGuide')},
        {checkKey: 'relieveOtherTravelModes', inputKey: 'relieveOtherInformation', label: this.$i18n.t("checkIn.formTravelMode_5"), placeholder: this.$i18n.t('checkIn.inputGuide')},
      ]
    }, // 交通工具复选
    // 承诺书和入住书的表单字段信息
    submitDisabled() {
      if(this.form.hasRoommate == 1) {
        return !(this.checked_1 && this.checked_2 && this.checked_3)
      } else {
        return !(this.checked_2 && this.checked_3)
      }
    },
    ...mapState({
      'commitmentForm': state => state.params.commitment,
      'notificationFileForm': state => state.params.notificationFile,
    })
  },
  methods: {
    onSubmit() {
      if(!this.form.autographUrl) {
        // this.$notify({ type: 'warning', message: this.$i18n.t('commitment.tipsSignatureSave_1') })
        Toast(this.$i18n.t('checkIn.signNameTips'))
        return
      }
      this.form.goTo = this.form.area.replace(/\//g, '') + this.form.areaDetail
      this.transformData(this.diseasesList, this.diseasesValue)
      this.transformData(this.healthStatusList, this.healthStatusValue)
      this.transformTravelData(this.travelModeList, this.formTravelMode)
      this.transformTravelData(this.fromReleaseModeList, this.formFromReleaseMode)
      let params = Object.assign({}, this.form, this.commitmentForm, this.notificationFileForm)
      console.log('params', params)
      this.submitLoading = true
      commitForm(params).then((res) => {
        if(res.code === 200) {
          // Toast.success(this.$i18n.t('common.registeredSuccessfully'))
          this.$router.push({ path: "/commit-success" })
        }
        this.submitLoading = false
      })
    },
    // 疾病、健康状况数据转换
    transformData(allList, checkList) {
      allList.forEach((item) => {
        this.form[item.key] = 0
        checkList.forEach((k) => {
          if(k === item.name) {
            this.form[item.key] = 1
          }
        })
      })
    },
    // 出行方式、交通工具数据转换
    transformTravelData(allList, sourceList) {
      allList.forEach((item) => {
          this.form[item.checkKey] = sourceList[item.checkKey] ? 1 : 0
      })
    },
    onConfirm(value, index) {
      this.form.documentTypeText = value
      this.form.documentType = index + 1
      this.showPicker = false
    },
    onDateConfirm(date) {
      if (this.dateType === "1") {
        // 登记日期
        this.form.registrationDate = this.formatDate(date)
      } else if (this.dateType === "2") {
        // 填报日期
        this.form.fillingDate = this.formatDate(date)
      } else if (this.dateType === "3") {
        this.form.entryTime = this.formatDate(date)
      }
      this.showCalendar = false;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onAreaConfirm(values) {
      this.form.area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    onShowCalendar(type) {
      this.dateType = type;
      this.showCalendar = true;
    },
    onSaveSign(imgUrl) {
      console.log("签名收到：", imgUrl)
      this.form.autographUrl = imgUrl
    },
    check(type) {
      if (type === 1) {
        this.$router.push({ path: "commitment" });
      } else if (type === 2) {
        this.$router.push({ path: "notification-file" });
      }
    },
    checkboxClick(type) {
      if(type === 1) {
        const keys = Object.keys(this.commitmentForm)
        for(let i = 0; i < keys.length; i++) {
          if(!this.commitmentForm[keys[i]]) {
            Toast(this.$i18n.t("checkIn.tipsSignCommitment"))
            this.checked_1 = false
            break
          }
        }
      } else if (type === 2) {
        console.log(this.notificationFileForm)
        const keys = Object.keys(this.notificationFileForm)
        for(let i = 0; i < keys.length; i++) {
          if(!this.notificationFileForm[keys[i]]) {
            Toast(this.$i18n.t("checkIn.tipsSignNotificationFile"))
            this.checked_2 = false
            break
          }
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/styles/common.scss";

.check-in_form {
  .address-icon {
    display: flex;
    align-items: baseline;
  }
  .check-in_header-card {
    .check-in-title {
      font-size: 20px;
      color: $primaryColor;
      max-width: 70%;
      display: inline-block;
    }
    .check-in_card {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      border-radius: 10px;
      padding: 10px;
      background-color: $primaryColor;
      color: #ffffff;
      img.check-in_card-left {
        width: 30%;
        border: 1px solid #ffffff;
        border-radius: 4px;
      }
      .check-in_card-right {
        width: 70%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        margin-left: 20px;
        p {
          margin-bottom: 6px;
          a {
            color: inherit;
          }
        }
        .hotel-name {
          font-size: 18px;
        }

        .hotel-phone,
        .hotel-address {
          font-size: 14px;
          display: flex;
          align-items: flex-start;
          line-height: 15px;

          img {
            margin-right: 3px;
          }
        }
      }
    }
    .tips {
      border-radius: 6px;
    }
  }
  .check-in_form-card {
    .idCard-tips {
      font-size: 12px;
    }
    .check-in_form-card__title {
      text-align: left;
      margin: 10px 0;
      font-size: 18px;
    }
    .check-in_form-card__detail {
      padding: 12px;
      border-radius: 10px;
      background-color: $primaryFormBgColor;
      border: 1px solid #eaeaea;
      .van-cell {
        // background-color: inherit;
        flex-direction: column;
        .van-field__label {
          width: 100%;
        }
      }
    }
    .check-in_ipt-field {
      .check-in_ways-item {
        display: flex;
        justify-content: flex-start;
        // align-items: baseline;
        align-items: center;
        .van-checkbox {
          width: 5rem;
        }
      }
      .custom-checkbox {
        .van-checkbox__label {
          min-width: 3.5rem;
        }
      }
      .check-in_ipt-field-right {
        width: 60%;
        margin-left: 10px;
      }
    }
  }
  .check-in_document-checkbox {
    .checkbox-item{
      display: flex;
      align-items: center;
      text-align: left;
      margin: 10px 0;
      .van-checkbox {
        max-width: 22px;
        min-width: 22px;
      }
      .notice-text {
        margin-left: 10px;
        line-height: 1.5;
        color: $dangerousColor;
      }
    }
  }
}

.check-in_commitment {
  .check-in_commitment-content {
    text-align: left;
    line-height: 3;
    .commitment-input-item {
      display: inline-block;
      padding: 0 4px;
      margin: 4px 0;
      vertical-align: middle;
      &.commitment-name {
        width: 80px;
      }
      &.commitment-gender {
        width: 60px;
      }
      &.commitment-idCardNum {
        width: 200px;
      }
      &.commitment-reson {
        width: 100px;
      }
      &.commitment-hotel-name {
        width: 100px;
      }
      &.commitment-room-num {
        width: 100px;
      }
      &.commitment-sign_year {
        width: 100px;
      }
      &.commitment-sign_month {
        width: 80px;
      }
      &.commitment-sign_day {
        width: 80px;
      }
    }
    .tips {
      margin-top: 20px;
      border-radius: 6px;
    }
  }
}

.check-in_notification-file {
  .check-in-title {
  }
  .check-in_file-content {
    text-align: left;
  }
}

.form-required-item {
  .van-cell__title {
    &::before {
      content: "*";
      color: red;
    }
  }
}

.main .van-field input.van-field__control,
.main .van-field textarea.van-field__control {
  padding-left: 12px;
}

.main .van-checkbox__label {
  margin: 0px !important;
}

.form-required-item.van-field {
  margin-bottom: 0px;
}

.main {
  .custom-checkbox {
    margin-bottom: 8px;
    // align-items: center !important;

    .van-field {
      margin-bottom: 0px;
    }
  }

  .van-field {
    margin-bottom: 12px;
  }

  .van-checkbox-group > div {
    margin-bottom: 8px;
  }

  .check-in_document-checkbox {
    .van-checkbox__icon--square {
      margin-right: 8px;
    }
  }
}

.submit-button {
  button {
    border-radius: 4px;
    background: #007aff;
  }
  .van-button::before {
    border-radius: 4px;
  }
}
</style>
