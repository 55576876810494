export const areaList = {
  province_list: { 100000: '北京', 110000: '上海', 120000: '天津', 130000: '重庆', 140000: '河北', 150000: '山西', 160000: '辽宁', 170000: '吉林', 180000: '黑龙江', 190000: '江苏', 200000: '浙江', 210000: '安徽', 220000: '福建', 230000: '江西', 240000: '山东', 250000: '河南', 260000: '湖北', 270000: '湖南', 280000: '广东', 290000: '海南', 300000: '四川', 310000: '贵州', 320000: '云南', 330000: '陕西', 340000: '甘肃', 350000: '青海', 360000: '内蒙古', 370000: '广西', 380000: '宁夏', 390000: '新疆', 400000: '西藏' },
  city_list: {
    100100: "东城",
    100200: "西城",
    100300: "朝阳",
    100400: "丰台",
    100500: "石景山",
    100600: "海淀",
    100700: "顺义",
    100800: "通州",
    100900: "大兴",
    101000: "房山",
    101100: "门头沟",
    101200: "昌平",
    101300: "平谷",
    101400: "密云",
    101500: "怀柔",
    101600: "延庆",
    110100: "黄浦",
    110200: "徐汇",
    110300: "长宁",
    110400: "静安",
    110500: "普陀",
    110600: "虹口",
    110700: "杨浦",
    110800: "浦东",
    110900: "闵行",
    111000: "宝山",
    111100: "嘉定",
    111200: "金山",
    111300: "松江",
    111400: "青浦",
    111500: "奉贤",
    111600: "崇明",
    120100: "和平",
    120200: "河东",
    120300: "河西",
    120400: "南开",
    120500: "河北",
    120600: "红桥",
    120700: "滨海",
    120800: "东丽",
    120900: "西青",
    121000: "津南",
    121100: "北辰",
    121200: "武清",
    121300: "宝坻",
    121400: "宁河",
    121500: "静海",
    121600: "蓟州",
    130100: "渝中",
    130200: "大渡口",
    130300: "江北",
    130400: "沙坪坝",
    130500: "九龙坡",
    130600: "南岸",
    130700: "北碚",
    130800: "渝北",
    130900: "巴南",
    131000: "涪陵",
    131100: "綦江",
    131200: "大足",
    131300: "长寿",
    131400: "江津",
    131500: "合川",
    131600: "永川",
    131700: "南川",
    131800: "璧山",
    131900: "铜梁",
    132000: "潼南",
    132100: "荣昌",
    132200: "万州",
    132300: "开州",
    132400: "黔江",
    132500: "梁平",
    132600: "武隆",
    132700: "城口",
    132800: "丰都",
    132900: "垫江",
    133000: "忠县",
    133100: "云阳",
    133200: "奉节",
    133300: "巫山",
    133400: "巫溪",
    133500: "石柱",
    133600: "秀山",
    133700: "酉阳",
    133800: "彭水",
    140100: "石家庄",
    140200: "唐山",
    140300: "秦皇岛",
    140400: "邯郸",
    140500: "邢台",
    140600: "保定",
    140700: "张家口",
    140800: "承德",
    140900: "沧州",
    141000: "廊坊",
    141100: "衡水",
    141200: "定州",
    141300: "辛集",
    150100: "太原",
    150200: "大同",
    150300: "阳泉",
    150400: "长治",
    150500: "晋城",
    150600: "朔州",
    150700: "晋中",
    150800: "运城",
    150900: "忻州",
    151000: "临汾",
    151100: "吕梁",
    160100: "沈阳",
    160200: "大连",
    160300: "鞍山",
    160400: "抚顺",
    160500: "本溪",
    160600: "丹东",
    160700: "锦州",
    160800: "营口",
    160900: "阜新",
    161000: "辽阳",
    161100: "盘锦",
    161200: "铁岭",
    161300: "朝阳",
    161400: "葫芦岛",
    170100: "长春",
    170200: "吉林",
    170300: "四平",
    170400: "辽源",
    170500: "通化",
    170600: "白山",
    170700: "白城",
    170800: "松原",
    170900: "延边",
    171000: "长白山",
    171100: "梅河口",
    171200: "公主岭",
    180100: "哈尔滨",
    180200: "齐齐哈尔",
    180300: "鸡西",
    180400: "鹤岗",
    180500: "双鸭山",
    180600: "大庆",
    180700: "伊春",
    180800: "佳木斯",
    180900: "七台河",
    181000: "牡丹江",
    181100: "黑河",
    181200: "绥化",
    181300: "大兴安岭",
    190100: "南京",
    190200: "无锡",
    190300: "徐州",
    190400: "常州",
    190500: "苏州",
    190600: "南通",
    190700: "连云港",
    190800: "淮安",
    190900: "盐城",
    191000: "扬州",
    191100: "镇江",
    191200: "泰州",
    191300: "宿迁",
    200100: "杭州",
    200200: "宁波",
    200300: "温州",
    200400: "绍兴",
    200500: "湖州",
    200600: "嘉兴",
    200700: "金华",
    200800: "衢州",
    200900: "台州",
    201000: "丽水",
    201100: "舟山",
    210100: "合肥",
    210200: "芜湖",
    210300: "蚌埠",
    210400: "淮南",
    210500: "马鞍山",
    210600: "淮北",
    210700: "铜陵",
    210800: "安庆",
    210900: "黄山",
    211000: "阜阳",
    211100: "宿州",
    211200: "滁州",
    211300: "六安",
    211400: "宣城",
    211500: "池州",
    211600: "亳州",
    220100: "福州",
    220200: "厦门",
    220300: "漳州",
    220400: "泉州",
    220500: "三明",
    220600: "莆田",
    220700: "南平",
    220800: "龙岩",
    220900: "宁德",
    221000: "平潭",
    230100: "南昌",
    230200: "九江",
    230300: "上饶",
    230400: "抚州",
    230500: "宜春",
    230600: "吉安",
    230700: "赣州",
    230800: "景德镇",
    230900: "萍乡",
    231000: "新余",
    231100: "鹰潭",
    240100: "济南",
    240200: "青岛",
    240300: "淄博",
    240400: "枣庄",
    240500: "东营",
    240600: "烟台",
    240700: "潍坊",
    240800: "济宁",
    240900: "泰安",
    241000: "威海",
    241100: "日照",
    241200: "滨州",
    241300: "德州",
    241400: "聊城",
    241500: "临沂",
    241600: "菏泽",
    241700: "莱芜 ",
    250100: "郑州",
    250200: "开封",
    250300: "洛阳",
    250400: "平顶山",
    250500: "安阳",
    250600: "鹤壁",
    250700: "新乡",
    250800: "焦作",
    250900: "濮阳",
    251000: "许昌",
    251100: "漯河",
    251200: "三门峡",
    251300: "商丘",
    251400: "周口",
    251500: "驻马店",
    251600: "南阳",
    251700: "信阳",
    251800: "济源",
    260100: "武汉",
    260200: "黄石",
    260300: "十堰",
    260400: "宜昌",
    260500: "襄阳",
    260600: "鄂州",
    260700: "荆门",
    260800: "孝感",
    260900: "荆州",
    261000: "黄冈",
    261100: "咸宁",
    261200: "随州",
    261300: "恩施",
    261400: "仙桃",
    261500: "潜江",
    261600: "天门",
    261700: "神农架",
    270100: "长沙",
    270200: "株洲",
    270300: "湘潭",
    270400: "衡阳",
    270500: "邵阳",
    270600: "岳阳",
    270700: "常德",
    270800: "张家界",
    270900: "益阳",
    271000: "娄底",
    271100: "郴州",
    271200: "永州",
    271300: "怀化",
    271400: "湘西",
    280100: "广州",
    280200: "深圳",
    280300: "珠海",
    280400: "汕头",
    280500: "佛山",
    280600: "韶关",
    280700: "湛江",
    280800: "肇庆",
    280900: "江门",
    281000: "茂名",
    281100: "惠州",
    281200: "梅州",
    281300: "汕尾",
    281400: "河源",
    281500: "阳江",
    281600: "清远",
    281700: "东莞",
    281800: "中山",
    281900: "潮州",
    282000: "揭阳",
    282100: "云浮",
    290100: "海口",
    290200: "三亚",
    290300: "三沙",
    290400: "儋州",
    290500: "五指山",
    290600: "文昌",
    290700: "琼海",
    290800: "万宁",
    290900: "东方",
    291000: "定安",
    291100: "屯昌",
    291200: "澄迈",
    291300: "临高",
    291400: "白沙",
    291500: "昌江",
    291600: "乐东",
    291700: "陵水",
    291800: "保亭",
    291900: "琼中",
    292000: "洋浦",
    300100: "成都",
    300200: "绵阳",
    300300: "自贡",
    300400: "攀枝花",
    300500: "泸州",
    300600: "德阳",
    300700: "广元",
    300800: "遂宁",
    300900: "内江",
    301000: "乐山",
    301100: "资阳",
    301200: "宜宾",
    301300: "南充",
    301400: "达州",
    301500: "雅安",
    301600: "阿坝",
    301700: "甘孜",
    301800: "凉山",
    301900: "广安",
    302000: "巴中",
    302100: "眉山",
    310100: "贵阳",
    310200: "遵义",
    310300: "六盘水",
    310400: "安顺",
    310500: "铜仁",
    310600: "毕节",
    310700: "黔西南",
    310800: "黔东南",
    310900: "黔南",
    320100: "昆明",
    320200: "曲靖",
    320300: "玉溪",
    320400: "昭通",
    320500: "保山",
    320600: "丽江",
    320700: "普洱",
    320800: "临沧",
    320900: "德宏",
    321000: "怒江",
    321100: "迪庆",
    321200: "大理",
    321300: "楚雄",
    321400: "红河",
    321500: "文山",
    321600: "西双版纳",
    330100: "西安",
    330200: "宝鸡",
    330300: "咸阳",
    330400: "渭南",
    330500: "铜川",
    330600: "延安",
    330700: "榆林",
    330800: "安康",
    330900: "汉中",
    331000: "商洛",
    340100: "兰州",
    340200: "嘉峪关",
    340300: "金昌",
    340400: "白银",
    340500: "天水",
    340600: "酒泉",
    340700: "张掖",
    340800: "武威",
    340900: "定西",
    341000: "陇南",
    341100: "平凉",
    341200: "庆阳",
    341300: "临夏",
    341400: "甘南",
    350100: "西宁",
    350200: "海东",
    350300: "海北",
    350400: "黄南",
    350500: "海南",
    350600: "果洛",
    350700: "玉树",
    350800: "海西",
    360100: "呼和浩特",
    360200: "包头",
    360300: "乌海",
    360400: "赤峰",
    360500: "通辽",
    360600: "鄂尔多斯",
    360700: "呼伦贝尔",
    360800: "巴彦淖尔",
    360900: "乌兰察布",
    361000: "兴安盟",
    361100: "锡林郭勒盟",
    361200: "阿拉善盟",
    370100: "南宁",
    370200: "柳州",
    370300: "桂林",
    370400: "梧州",
    370500: "北海",
    370600: "防城港",
    370700: "钦州",
    370800: "贵港",
    370900: "玉林",
    371000: "百色",
    371100: "贺州",
    371200: "河池",
    371300: "来宾",
    371400: "崇左",
    380100: "银川",
    380200: "石嘴山",
    380300: "吴忠",
    380400: "固原",
    380500: "中卫",
    390100: "乌鲁木齐",
    390200: "克拉玛依",
    390300: "吐鲁番",
    390400: "哈密",
    390500: "阿克苏",
    390600: "喀什",
    390700: "和田",
    390800: "昌吉",
    390900: "博州",
    391000: "巴州",
    391100: "克州",
    391200: "伊犁",
    400100: "拉萨",
    400200: "昌都",
    400300: "日喀则",
    400400: "林芝",
    400500: "山南",
    400600: "那曲",
    400700: "阿里",
  },
  county_list: {},
};
