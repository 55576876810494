import Vue from "vue";
import VueI18n from "vue-i18n";

import { getLanguage } from "@/utils/cookies";

// Vant built-in lang
import { Locale } from 'vant';
import enUS from "vant/es/locale/lang/en-US";
import zhCN from "vant/es/locale/lang/zh-CN";

// User defined lang
import enUsLocale from "./en_US";
import zhCnLocale from "./zh_CN";

Vue.use(VueI18n);

const messages = {
  'zh-CN': {
    ...zhCN,
    ...zhCnLocale
  },
  'en-US': {
    ...enUS,
    ...enUsLocale
  },
};

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage;
    return cookieLanguage;
  }

  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale.toLowerCase()) > -1) {
      document.documentElement.lang = locale;
      return locale;
    }
  }

  // Default language is chinese
  return "zh-CN";
};

const CURRENT_LANG = getLocale();
// first entry
Locale.use(CURRENT_LANG, messages[CURRENT_LANG])

const i18n = new VueI18n({
  locale: CURRENT_LANG,
  messages,
  silentTranslationWarn: true, // 去除国际化警告
});

export default i18n;
