<template>
  <div class="signature">
    <div class="mask" v-show="visible">
      <p>{{$t('checkIn.signTips')}}</p>
      <div class="click-area" @click="visible = false">
        {{$t('checkIn.signGuide')}}
      </div>
    </div>
    <div>
      <vue-esign ref="esign" :width="canvasW" :height="canvasH" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
    </div>
    <div class="preview" v-if="isPreview" @click="previewImgClick">
      <img :src="resultImg">
    </div>
    <div class="button-item">
      <van-button @click="close" size="mini" native-type="button">{{$t("signature.close")}}</van-button>
      <van-button @click="handleReset" type="info" size="mini" native-type="button">{{$t("signature.clear")}}</van-button>
      <van-button @click="handleGenerate" type="primary" size="mini" native-type="button">{{$t("signature.save")}}</van-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
import { DEFAULT_DOMAIN, DEFAULT_PATH } from '@/utils/constants'

export default {
  name: "Signature",
  props: {
    
  },
  data() {
    return {
      // 签名参数
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
      resultImg: '',
      resultImgUrl: '',
      isCrop: false,
      canvasW: 740,
      canvasH: 555,
      visible: true,
      isPreview: false,
    }
  },
  methods: {
    // 签名
    handleGenerate () {
      this.$refs.esign.generate().then(res => {
        this.resultImg = res
        var arr = res.split(',')
        const data = window.atob(arr[1]);
        const ia = new Uint8Array(data.length);
        for(let i = 0; i < data.length;i++) {
            ia[i] = data.charCodeAt(i);
        }
        const blob = new Blob([ia], { type: 'image/png' })
        let file = new File([blob], 'a.png')
        let formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'main')
        axios({
          method: 'post',
          url: DEFAULT_DOMAIN + DEFAULT_PATH + '/util/file/upload',
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        }).then(res => {
          if(res.data.code === 200) {
            Toast.success(this.$i18n.t('common.saveSuccessfully'))
            // this.$notify({ type: 'success', message: '保存成功' })
            setTimeout(() => {
              this.visible = true
            }, 500)
            this.resultImgUrl = res.data.result
            console.log('签名地址：', this.resultImgUrl)
            this.$emit('save', this.resultImgUrl)
            this.isPreview = true
          } else {
            Toast.fail(this.$i18n.t('common.saveFail'))
            // this.$notify({ type: 'danger', message: '保存失败，请重试' })
          }
        })

      }).catch(err => {
        console.log(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    },
    previewImgClick() {
      this.isPreview = false
      this.visible = false
    },
    handleReset () {
      this.$refs.esign.reset()
      this.resultImg = ''
      this.resultImgUrl = ''
      this.$emit('clear')
    },
    close() {
      this.isPreview = this.resultImg && this.resultImgUrl
      this.visible = !this.resultImgUrl

      // this.$emit('close')
    }
  },
  mounted() {
    // this.canvasW = 740
    // this.canvasH = this.canvasW * 3 / 4
  }
};
</script>

<style lang="scss" scoped>
.signature {
  position: relative;
  border: 1px solid #C2C9D0;
  border-radius: 4px;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #fff;
    z-index: 999;
    p {
      text-align: left;
      padding-left: 10px;
    }
    .click-area {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      line-height: 1.5;
      text-align: center;
    }
  }
  .preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #fff;
    z-index: 1000;
    img {
      width: 100%;
    }
  }
  .button-item {
    position: absolute;
    top: 4px;
    left: 4px;
  }
}
</style>