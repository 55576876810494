export default {
  appHeader: {
    checkIn_title: '酒店入住登记',
  },
  common: {
    registeredSuccessfully: '登记成功',
    saveSuccessfully: '保存成功',
    saveFail: '保存失败，请重试',
  },
  checkIn: {
    pageTitle: '东园大厦隔离酒店入住登记',
    hotelName: '东园大厦隔离酒店',
    hotelAddress: '广东省广州书黄埔区西区东园一街东园大厦',
    tips: '为做好新冠肺炎防治工作，请您配合我们做好流行病学史调查',
    formTitleBaseInfo: '基本信息',
    formUserName: '姓名',
    formGender: '性别',
    formCountry: '国籍',
    formIdCardType: '证件类型',
    formIDCardType_1: '居民身份证',
    formIDCardType_2: '居民往来内地通行证',
    formIDCardType_3: '护照',
    formIDCardType_4: '居民来往大陆通行证或者其他有效旅行证件',
    formIdCardTypeTips: '中国公民为居民身份证；香港、澳门居民，为港澳居民往来内地通行证；台湾居民，我台湾居民来往大陆通行证或者其他有效旅行证件；外国公民，为护照',
    formIdCardNum: '证件号码',
    formYearOld: '年龄',
    formPhoneNum: '手机号码',
    formEmail: '邮箱',
    formArea: '国内住址所属区',
    formAddress: '国内住址',
    formEmergencyContactName: '紧急联系人姓名',
    formEmergencyContactPhoneNum: '紧急联系人电话',
    formContactNameAndWay: '第二联系人（中方）和电话号码',
    formDiseases: '有无相关疾病',
    formDiseasesHypertension: '高血压',
    formDiseasesDiabetes: '糖尿病',
    formDiseasesCoronaryHeart: '冠心病',
    formDiseasesChronicHepatitis: '慢性肝炎',
    formDiseasesHeart: '心脏病',
    formDiseasesNothing: '无',
    formFoodAllergy: '食物有无过敏',
    formFoodAllergiesDetails: '请详细写明哪种食物过敏',
    formReligious: '宗教信仰',
    formRequirements: '特殊要求（尽量满足）',
    formRegistrationDate: '登记日期',
    formTitle14DSituation: '近14天情况',
    formFromAbroad: '是否来自境外',
    formEntryDate: '入境日期',
    formCountryAndCity: '近14天内旅行或者居住国家（地区）、城市',
    formCountryAndCityAndDate: '入境前途径国家（地区）、城市及相应日期',
    formGetTouchDate: '是否接触可疑病例（是、否），日期',
    formTravelMode: '出行方式',
    formTravelMode_1: '火车车次及出发日期时间',
    formTravelMode_2: '飞机航班及座位',
    formTravelMode_3: '汽车班次及车牌',
    formTravelMode_4: '自家车牌',
    formTravelMode_5: '其他',
    formPeersNameAndWay: '同行人员姓名和联系方式',
    formTitleReleaseDiction: '解除隔离后去向',
    formReleaseRegion: '地区',
    formReleaseAddress: '详细地址',
    formReleaseVehicle: '交通工具',
    formTitleHealthStatus: '目前健康状况',
    formTemperature: '体温',
    formHealthStatus: '目前健康状况',
    formHealthStatusFever: '发热',
    formHealthStatusDryCough: '干咳',
    formHealthStatusWeakness: '乏力',
    formHealthStatusSoreThroat: '咽痛',
    formHealthStatusChestTightness: '胸闷',
    formHealthStatusOtherSymptoms: '其他症状',
    formHealthStatusNoneOfTheAbove: '无上述异常症状',
    formSignDate: '填报日期',
    formRoommate: '是否有同住人',
    checkboxText_1: '本人已阅读并同意《同住申请承诺书》，请点击进入填写',
    checkboxText_2: '本人已阅读并同意《集中隔离观察点入住告知书》，请点击进入填写',
    checkboxText_3: '本人承诺以上提供的资料真实准确。如有不是，本人愿意承担由此引起的一切后果及法律责任。',
    signTips: '填写人签名（点击图片可重新签名）',
    signGuide: '点击签名',
    signNameTips: '请签名',
    men: '男',
    women: '女',
    have: '有',
    without: '无',
    certPlace: '点击选择证件类型',
    datePlace: '点击选择日期',
    areaPlace: '点击选择省市区',
    inputGuide: '请输入',
    shortInputGuide: '请输入',
    submit: '提交',
    tipsSignCommitment: '请点击填写同住申请通知书',
    tipsSignNotificationFile: '请点击填写集中隔离观察点入住告知书',
  },
  commitment: {
    pageTitle: '承诺书',
    name: '本人姓名',
    sex: '性别',
    idNumber: '身份证号/护照号',
    reason: '因',
    partner: '自愿与拟同住人姓名',
    room: '在广州市隔离酒店集中隔离医学观察期间同住一间房',
    alert: '工作人员已告知本人及拟同住人防控工作要求和存在交叉感染等风险，也可能出现同住人核酸检查阳性时需再隔离14天的情况。但本人及拟同住人仍坚持要求同住一间房，并自愿严格遵守各项防疫规定，自愿由本人及拟同住人承担所有风险及法律责任。',
    sure: '本人保证所填写资料完全属实，如有虚假信息，本人自愿承担所有法律责任。',
    hotel: '酒店',
    roomId: '房号',
    sign: '承诺人签名',
    private: '本人已完全了解上述防控要求、感染风险和法律责任，仍坚持要求与承诺人同住一间房，并自愿严格遵守各项防疫规定，自愿由本人及承诺人承担所有风险及法律责任。',
    partnerSign: '拟同住人签名',
    desc: '备注：如因疫情防控需要，被通知分房时需补住房差价。',
    inputGuide: '请输入',
    shortInputGuide: '请输入',
    year: '年',
    month: '月',
    day: '日',
    tipsSignatureSave_1: `请保存承诺人签名`,
    tipsSignatureSave_2: `请保存同住人签名`,
  },
  notificationFile: {
    pageTitle: '集中隔离观察点入住告知书',
    content_1: '1.严格遵守集中隔离点的所有相关制度，在集中隔离期间严禁有擅自外出、相互串门，会见访客等影响疫情防控的行为。',
    content_2: '2.积极配合医务人员开展隔离观察，每天2次体温测量(7:00、14：00各一次），并如实汇报本人当天的健康情况。',
    content_3: '3.不擅自传播疫情相关信息，不瞒报、不谎报、不迟报自己的相关情况。',
    content_4: '4. 观察期间，如出现发热、咳嗷、味觉嗅觉丧失等症状，应立即电话通知总台工作人员，配合工作人员开展相关查验工作，',
    content_5: '5.每天三餐的餐食由工作人员定时放置房间门口，由隔离人员自取。生活垃圾由隔离人员自行收纳，并将垃圾袋口扎紧后放置房间走廊门口，每日上午由工作人员前来收集，新的垃圾袋也会定时放置房间门口。隔离人员取餐时和放置垃圾时或其他需要开房间门时须佩戴好口罩后方可开门，其他时间如无特殊情况禁止走出房门。',
    content_6: '6.隔离期间，三餐由餐饮公司统一配送，可能无法满足每位隔离人员的个性需求，请您谅解。（如有食物过敏等特殊情况，请及时告知工作人员）',
    content_7: '7.为了保证食品安全和疫情防控需要，隔离期间不许外卖餐饮食品（如需药物可提前与工作人员联系）；为了隔离人员的身体健康与安全，不得带入酒类食品进入房间。',
    content_8: '8.禁止吸烟，禁止饮酒，禁止串门，如不配合隔离工作，将影响你的隔离解除时间。如若造成严重后果，将依法追究其刑事责任。',
    content_9: '9.隔离人员携带宠物的，宠物应与携带者一起隔离，隔离期间禁止携出房间，宠物造成的垃圾和排泄物也应有隔离人员自行清理千净并消毒。',
    content_10: '10.隔离人员禁止其他隔离对象做不符合实际的评论。',
    content_11: '11.本隔离点为隔离人员提供心理热线咨询渠道，如有需求可拨打以下咨询热线电话：',
    content_12: '12.本集中隔离观察点是“东园大厦酒店隔离点”，地址：广东省广州市黄埔区西区东园一街东园大厦',
    content_13: '广州市心理危机干预中心热线：020-81899120(24小时）',
    content_14: '黄埔区精神卫生防治中心心理援助：15915923478 (24小时）',
    idCardUpload: '身份证/护照上传（有本人照片的一页）',
    date: '日期',
  },
  commitResult: {
    pageTitle: '提交结果',
    message: '提交成功',
  },
  signature: {
    close: '关闭',
    clear: '清除',
    save: '保存',
  },
  langSelect: {
    pickerTitle: '当前语言',
    text: 'Click to switch between Chinese and English versions',
  }
}