import axios from 'axios'
import { Notify } from 'vant'
import { DEFAULT_DOMAIN, DEFAULT_PATH } from '@/utils/constants'

const BASE_API = DEFAULT_DOMAIN + DEFAULT_PATH

// 创建axios实例
const service = axios.create({
  baseURL: BASE_API, // api的base_url
  timeout: 15000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  console.log(config)
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * code为非200是抛错 可结合自己业务进行修改
  */
    const res = response.data
    if (res.code !== 200) {
      Notify({ type: 'danger', message: '网络请求错误，请稍后再试' })
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error)// for debug
    Notify({ type: 'danger', message: '网络请求错误，请稍后再试' })
    return Promise.reject(error)
  }
)

export default service
