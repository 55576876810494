import Vue from 'vue'
import Vuex from 'vuex'
import lang from './modules/lang'
import params from './modules/params'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    lang,
    params,
  },
})