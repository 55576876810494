<template>
  <div class="lang-toggle">
    <van-notice-bar
      class="notice-bar"
      wrapable
      :scrollable="false"
      background="#007AFF"
      color="#ffffff"
      @click="toggle = !toggle"
    >
      <img v-if="!toggle" style="width: 22px" src=".././../assets/lang_cn.png" />
      <img v-else style="width: 22px" src=".././../assets/lang_en.png" />
      <div class="notice-text">
        {{$t("langSelect.text")}}
      </div>
    </van-notice-bar>
  </div>
</template>

<script>
import { setLanguage, getLanguage } from "@/utils/cookies";

export default {
  name: "LangToggle",
  data() {
    return {
      toggle: true,
      langs: [
        {
          text: '中文（简体）',
          value: 'zh-CN'
        },
        {
          text: 'English',
          value: 'en-US'
        },
      ]
    };
  },
  watch: {
    toggle(val) {
      this.setLang(val)
    }
  },
  methods: {
    setLang(status) {
      // Business component
      let lang = ''
      if(status) {
        lang = this.langs[0].value
      } else {
        lang = this.langs[1].value
      }
      this.$i18n.locale = lang
      document.documentElement.lang = lang
      // Cookie
      setLanguage(lang)
      this.$store.dispatch('lang/setLanguage', lang)
    },
  },
  mounted() {
    const cookieLanguage = getLanguage();
    this.toggle = cookieLanguage === this.langs[0].value
  }
};
</script>

<style lang="scss">
.notice-bar {
  font-size: 12px;
  border-radius: 4px;
  padding: 5px 8px 4px;
  .van-notice-bar__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    width: 22px;
    height: 22px;
  }
  .notice-text {
    padding-left: 8px;
    text-align: left;
    line-height: 1;
  }
}
</style>