<template>
  <!-- 通知文件 -->
  <div class="main">
    <div class="check-in_notification-file">
      <p class="check-in-title">{{ $t("notificationFile.pageTitle") }}</p>
      <div class="check-in_file-content">
        <br />
        <p>{{ $t("notificationFile.pageTitle") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_1") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_2") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_3") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_4") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_5") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_6") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_7") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_8") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_9") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_10") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_11") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_13") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_14") }}</p>
        <br />
        <p>{{ $t("notificationFile.content_12") }}</p>
        <br />
        <van-form @submit="onSubmit">
          <p>
            <!-- 签名 -->
            <signature @save="onSaveSignature"></signature>
          </p>
          <!-- 身份证上传 -->
          <!-- <div>
            <p>{{$t('notificationFile.idCardUpload')}}</p>
            <van-uploader :after-read="afterRead" />
          </div> -->
          <div>
            <p>{{ $t("notificationFile.date") }}</p>
            <van-field
              readonly
              clickable
              name="calendar"
              :value="form.noticeDate"
              :rules="[{ required: true}]"
              :placeholder="$t('checkIn.datePlace')"
              @click="showCalendar = true"
            />
          </div>
          <div class="submit-button">
            <van-button :loading="submitLoading" round block type="info" native-type="submit"
              >{{
          $t("checkIn.submit")
        }}</van-button
            >
          </div>
        </van-form>
        <van-calendar
          v-model="showCalendar"
          :min-date="minDate"
          @confirm="onDateConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import Signature from "@/components/Signature"

export default {
  name: "NotificationFile",
  components: {
    Signature,
  },
  props: {},
  data() {
    return {
      form: {
        noticeAutographUrl: "", // 告知书签名图片链接
        // idCardUrl: "", // 告知书签名图片链接
        noticeDate: "", // 通知书填写日期 yyyy-mm-dd
      },
      showCalendar: false,
      minDate: new Date(2010, 0, 1), // 日历最小范围
      submitLoading: false,
    }
  },
  methods: {
    onSubmit(values) {
      console.log("submit", values)
      if(!this.form.noticeAutographUrl) {
        // this.$notify({ type: 'warning', message: this.$i18n.t('commitment.tipsSignatureSave_1') })
        Toast(this.$i18n.t('commitment.tipsSignatureSave_1'))
        return
      }
      this.submitLoading = true
      setTimeout(() => {
        this.$store.dispatch('params/saveNotificationFileParams', this.form)
        this.$router.push({ path: "/" })
        this.submitLoading = false
      }, 300)
    },
    onSaveSignature(imgUrl) {
      this.form.noticeAutographUrl = imgUrl
    },
    onDateConfirm(date) {
      this.form.noticeDate = this.formatDate(date);
      this.showCalendar = false;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.check-in_notification-file {
  .check-in_file-content {
    text-align: left;
  }
}
</style>