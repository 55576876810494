<template>
  <div class="main">
    <!-- 承诺书 -->
    <div class="check-in_commitment">
      <p class="check-in-title">{{ $t("commitment.pageTitle") }}</p>
      <div class="check-in_commitment-content">
        <van-form @submit="onSubmit">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ $t("commitment.name") }}：<van-field
            class="commitment-input-item commitment-name"
            v-model="form.applicantName"
            :placeholder="$t('commitment.inputGuide')"
            :rules="[{ required: true}]"
          />
          {{ $t("commitment.sex") }}：<van-field
            class="commitment-input-item commitment-gender"
            v-model="form.applicantSex"
            :placeholder="$t('commitment.shortInputGuide')"
            :rules="[{ required: true}]"
          />
          {{ $t("commitment.idNumber") }}：<van-field
            class="commitment-input-item commitment-idCardNum"
            v-model="form.applicantIdNumber"
            :rules="[{ required: true}]"
            :placeholder="$t('commitment.inputGuide')"
          />{{ $t("commitment.reason") }}<van-field
            class="commitment-input-item commitment-reson"
            v-model="form.applicantReason"
            :rules="[{ required: true}]"
            :placeholder="$t('commitment.inputGuide')"
          />，{{
            $t("commitment.partner")
          }}：<van-field
            class="commitment-input-item commitment-name"
            v-model="form.roommateName"
            :placeholder="$t('commitment.inputGuide')"
            :rules="[{ required: true}]"
          />
          {{ $t("commitment.sex") }}：<van-field
            class="commitment-input-item commitment-gender"
            v-model="form.roommateSex"
            :placeholder="$t('commitment.shortInputGuide')"
            :rules="[{ required: true}]"
          />
          {{ $t("commitment.idNumber") }}：<van-field
            class="commitment-input-item commitment-idCardNum"
            v-model="form.roommateIdNumber"
            :rules="[{ required: true}]"
            :placeholder="$t('commitment.inputGuide')"
          />
          {{ $t("commitment.room") }}
          {{ $t("commitment.alert") }}
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {{ $t("commitment.sure") }}
          </p>
          <p>
            <van-field
              class="commitment-input-item commitment-hotel-name"
              v-model="form.hotelName"
              :placeholder="$t('commitment.inputGuide')"
              :rules="[{ required: true}]"
            />
            {{ $t("commitment.hotel") }}
            <van-field
              class="commitment-input-item commitment-room-num"
              v-model="form.hotelRoomNumber"
              :placeholder="$t('commitment.shortInputGuide')"
              :rules="[{ required: true}]"
            />
            {{ $t("commitment.roomId") }}
          </p>
          <p>{{ $t("commitment.sign") }}：</p>
          <p>
            <!-- 签名 -->
            <signature @save="onSaveSignature_1"></signature>
          </p>
          <p>
            <van-field
              class="commitment-input-item commitment-sign_year"
              v-model="form.applicantAutographYear"
              :placeholder="$t('commitment.shortInputGuide')"
              :rules="[{ required: true}]"
            />{{ $t("commitment.year") }}
            <van-field
              class="commitment-input-item commitment-sign_month"
              v-model="form.applicantAutographMonth"
              :placeholder="$t('commitment.shortInputGuide')"
              :rules="[{ required: true}]"
            />{{ $t("commitment.month") }}
            <van-field
              class="commitment-input-item commitment-sign_day"
              v-model="form.applicantAutographDay"
              :placeholder="$t('commitment.shortInputGuide')"
              :rules="[{ required: true}]"
            />{{ $t("commitment.day") }}
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {{ $t("commitment.private") }}
          </p>
          <p>{{ $t("commitment.partnerSign") }}：</p>
          <p>
            <!-- 签名 -->
            <signature @save="onSaveSignature_2"></signature>
          </p>
          <p>
            <van-field
              class="commitment-input-item commitment-sign_year"
              v-model="form.roommateAutographYear"
              :placeholder="$t('commitment.shortInputGuide')"
              :rules="[{ required: true}]"
            /> {{ $t("commitment.year") }}
            <van-field
              class="commitment-input-item commitment-sign_month"
              v-model="form.roommateAutographMonth"
              :placeholder="$t('commitment.shortInputGuide')"
              :rules="[{ required: true}]"
            />{{ $t("commitment.month") }}
            <van-field
              class="commitment-input-item commitment-sign_day"
              v-model="form.roommateAutographDay"
              :placeholder="$t('commitment.shortInputGuide')"
              :rules="[{ required: true}]"
            />{{ $t("commitment.day") }}
          </p>
          <van-notice-bar
            class="tips"
            color="#1989fa"
            background="#ecf9ff"
            left-icon="info-o"
          >
            {{ $t("commitment.desc") }}
          </van-notice-bar>
          <div class="submit-button">
            <van-button :loading="submitLoading" round block type="info" native-type="submit">{{
              $t("checkIn.submit")
            }}</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import Signature from "@/components/Signature"

export default {
  name: "Commitment",
  components: {
    Signature,
  },
  data() {
    return {
      form: {
        applicantName: "", // 申请人姓名 有同住人时需要填
        applicantSex: "", // 申请人性别 有同住人时需要填
        applicantIdNumber: "", // 申请人身份证号 有同住人时需要填
        applicantReason: "", // 申请人原因 有同住人时需要填
        applicantAutographUrl: "", // 申请人签名图片链接 有同住人时需要填
        applicantAutographYear: "", // 申请人签名 年 有同住人时需要填
        applicantAutographMonth: "", // 申请人签名 月 有同住人时需要填
        applicantAutographDay: "", // 申请人签名 日 有同住人时需要填
        roommateName: "", // 同住人姓名 有同住人时需要填
        roommateSex: "", // 同住人性别 有同住人时需要填
        roommateIdNumber: "", // 同住人身份证号码 有同住人时需要填
        roommateAutographUrl: "", // 同住人签名图片链接 有同住人时需要填
        roommateAutographYear: "", // 同住人签名 年 有同住人时需要填
        roommateAutographMonth: "", // 同住人签名 月 有同住人时需要填
        roommateAutographDay: "", // 同住人签名 日 有同住人时需要填
        hotelName: "", // 酒店名称 有同住人时需要填
        hotelRoomNumber: "", // 酒店房号 有同住人时需要填
      },
      submitLoading: false,
    };
  },
  methods: {
    onSubmit(values) {
      console.log("onCommitmentSubmit", values)
      if(!this.form.applicantAutographUrl) {
        // this.$notify({ type: 'warning', message: this.$i18n.t('commitment.tipsSignatureSave_1') })
        Toast(this.$i18n.t('commitment.tipsSignatureSave_1'))
        return
      }
      if(!this.form.roommateAutographUrl) {
        // this.$notify({ type: 'warning', message: this.$i18n.t('commitment.tipsSignatureSave_2') })
        Toast(this.$i18n.t('commitment.tipsSignatureSave_2'))
        return
      }
      this.submitLoading = true
      setTimeout(() => {
        this.$store.dispatch('params/saveCommitmentParams', this.form)
        this.$router.push({ path: "/" })
        this.submitLoading = false
      }, 300)
    },
    // 申请人签名
    onSaveSignature_1(imgUrl) {
      this.form.applicantAutographUrl = imgUrl
    },
    // 同住人签名
    onSaveSignature_2(imgUrl) {
      this.form.roommateAutographUrl = imgUrl
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.check-in_commitment {
  .check-in_commitment-content {
    text-align: left;
    line-height: 3;
    .commitment-input-item {
      display: inline-block;
      padding: 0 4px;
      margin: 4px 0;
      vertical-align: middle;
      &.commitment-name {
        width: 120px;
      }
      &.commitment-gender {
        width: 90px;
      }
      &.commitment-idCardNum {
        width: 230px;
      }
      &.commitment-reson {
        width: 150px;
      }
      &.commitment-hotel-name {
        width: 120px;
      }
      &.commitment-room-num {
        width: 100px;
      }
      &.commitment-sign_year {
        width: 100px;
      }
      &.commitment-sign_month {
        width: 80px;
      }
      &.commitment-sign_day {
        width: 80px;
      }
    }
    .tips {
      margin-top: 20px;
      border-radius: 6px;
    }
  }
}
</style>